import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Divider,
  Box,
  Stack,
  useDisclosure,
} from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Search from "../components/Search";
import Header from "../components/Header";
import BoxWhite from "../components/BoxWhite";
import Table from "../components/Table/Table";
import styles from "../components/Link/Link.module.css";
import RequestNewRefModal from "../components/Modal/RequestNewReference";
import documentBlue from "../images/document.svg";
import { auth, db } from "../firebase/firebase";
import getFormattedDate from "../utils/getFormattedDate";

const Dashboard = (props) => {
  const { user } = props;
  const [filterKeyword, setFilterKeyword] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);

  // Query new data
  useEffect(() => {
    const testFunc = async () => {
      const tempArr = [];
      try {
        const querySnapshot = await db.getAllReferences(user);

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          tempArr.push({ ...doc.data(), id: doc.id });
        });

        // Add icon svg for credit_detail
        const mappedData = tempArr
          .map((e) => {
            return {
              date: getFormattedDate(e.date.toDate()),
              customer_company: e.customerCompanyName,
              reference_company: e.referenceProviderCompany,
              reference_person: e.referenceProviderName,
              id: e.id,
            };
          })
          .map((item) => {
            return {
              ...item,
              credit_detail: (
                <Box
                  as={RouterLink}
                  display="flex"
                  justifyContent="center"
                  alignSelf="center"
                  to={`/form?id=${item.id}`}
                >
                  <img alt="document" src={documentBlue} />
                </Box>
              ),
            };
          });

        setData(mappedData);
      } catch (err) {
        console.log(err);
      }
    };
    if (user) {
      testFunc();
    }
  }, [user]);

  useEffect(() => {
    if (filterKeyword.length > 0) {
      setFilteredData(
        data.filter((item) => {
          return item.customer_company
            .toLowerCase()
            .match(filterKeyword.toLowerCase());
        })
      );
    } else {
      setFilteredData(data);
    }
  }, [filterKeyword, data]);

  const onSignOut = () => {
    auth.signOut();
    localStorage.removeItem("user");
  };

  return (
    <Flex w="100%" h="100%" direction="column" align="center">
      <Header user={user} />

      <Flex direction="column" width="80%">
        <Stack spacing={8}>
          <Box>
            <Flex>
              <Button
                variant="link"
                alignSelf="flex-start"
                color="#1b1919"
                rightIcon="plus-square"
                fontWeight="500"
                fontSize="18px"
                borderRadius="0"
                className={styles.LinkButton}
                onClick={onOpen}
                fontFamily="Avenir-Medium"
              >
                Request New Trade Reference
              </Button>
              <Button
                ml="auto"
                variant="link"
                alignSelf="flex-start"
                color="#1b1919"
                fontWeight="500"
                fontSize="18px"
                borderRadius="0"
                className={styles.LinkButton}
                onClick={onSignOut}
                fontFamily="Avenir-Medium"
              >
                Sign Out
              </Button>
            </Flex>
            <Divider />
          </Box>

          <BoxWhite
            w="100%"
            p="0"
            py="18px"
            px="50px"
            justify="flex-start"
            align="flex-start"
          >
            <Search filter={filterKeyword} setFilter={setFilterKeyword} />
          </BoxWhite>

          <Table data={filteredData} />
        </Stack>

        <RequestNewRefModal
          user={user}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Flex>
    </Flex>
  );
};

export default Dashboard;
