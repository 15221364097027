import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/core";
import Formik from "./Formik";
import { sendFilledForm } from "../firebase/firebase";
import fields from "../constants/fields/FormCreditInquiry";

const Form = (props) => {
  const {
    uid,
    customerCompanyName,
    isExternalLink,
    dbValues,
    setSubmitted,
  } = props;
  const toast = useToast();
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (props) => {
    setLoading(true);
    const response = await sendFilledForm({
      customerCompanyName,
      uid,
      ...props,
    });

    console.log(response);

    if (!response.data.ok) {
      toast({
        title: response.data.error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Sent",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSubmitted(true);
    }
  };

  const getEmptyInitialValues = () =>
    fields
      .map((field) => {
        return field.inputId
          ? { [field.inputId]: field.value ? field.value : "" }
          : null;
      })
      .filter((item) => item !== null)
      .reduce((acc, currentVal) => {
        return { ...acc, ...currentVal };
      });

  useEffect(() => {
    const emptyValues = getEmptyInitialValues();
    emptyValues.customerCompanyName = customerCompanyName;

    setInitialValues(emptyValues);
  }, [customerCompanyName]);

  return (
    <>
      {(initialValues || dbValues) && (
        <Formik
          initialValues={isExternalLink ? initialValues : dbValues}
          fields={fields}
          isDisabled={!isExternalLink}
          onSubmit={onSubmit}
          isLoading={loading}
          submitButtonTitle="Submit"
          inputWidth="460px"
        />
      )}
    </>
  );
};

export default Form;
