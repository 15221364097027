import React from "react";
import { Link } from "@chakra-ui/core";
import { Link as RouteLink } from "react-router-dom";
import propTypes from "prop-types";

const LinkBlue = (props) => {
  const { children, mr, ml, to } = props;
  return (
    <Link
      as={RouteLink}
      mr={mr}
      ml={ml}
      color="blue.500"
      fontSize="12px"
      to={to}
      fontFamily="Avenir-Medium"
    >
      {children}
    </Link>
  );
};

LinkBlue.propTypes = {
  children: propTypes.string.isRequired,
  mr: propTypes.string,
  ml: propTypes.string,
  to: propTypes.string.isRequired,
};

LinkBlue.defaultProps = {
  mr: "0",
  ml: "0",
};

export default LinkBlue;
