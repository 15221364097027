import React, { useState, useEffect } from "react";
import { Heading, useToast } from "@chakra-ui/core";
import Modal from "./Modal";
import BoxWhite from "../BoxWhite";
import Formik from "../Formik";

import {
  validateName,
  validateEmail,
  validatePassword,
} from "../../utils/validators";
import { auth } from "../../firebase/firebase";

const ChangePersonalDetails = (props) => {
  const { isOpen, onOpen, onClose } = props;
  const [submitted, setSubmitted] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      setSubmitted(false);
    }
  }, [isOpen]);

  const fields = [
    {
      inputPlaceholder: "Full Name",
      inputId: "fullName",
      type: "text",
      fieldName: "fullName",
      validate: validateName,
    },
    {
      inputPlaceholder: "Work Email",
      inputId: "email",
      type: "email",
      fieldName: "email",
      validate: validateEmail,
    },
    {
      inputPlaceholder: "Password",
      inputId: "password",
      type: "password",
      fieldName: "password",
      validate: validatePassword,
    },
  ];

  const onSubmit = async ({ fullName, password, email }) => {
    setSubmitted(true);
    // Close modal
    setTimeout(() => {
      onClose();
    }, 2000);
    try {
      await auth.updateUsersDisplayName({ fullName });
      await auth.updateUsersEmail(email);
      await auth.updateUsersPassword(password);

      toast({
        title: "Profile updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setSubmitted(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <BoxWhite
        rounded="md"
        p="30px 50px"
        w="100%"
        justify="center"
        align="center"
        direction="column"
      >
        <Heading
          as="h5"
          color="#5e5e5e"
          fontSize="22px"
          fontWeight="500"
          alignSelf="flex-start"
          fontFamily="Avenir-Medium"
        >
          Change Personal Details
        </Heading>

        <Formik
          initialValues={{
            fullName: "",
            email: "",
            password: "",
          }}
          fields={fields}
          onSubmit={onSubmit}
          submitButtonTitle="Submit"
          isLoading={submitted}
        />
      </BoxWhite>
    </Modal>
  );
};

export default ChangePersonalDetails;
