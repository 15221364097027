import React from "react";
import { Input, InputGroup, InputRightElement, Icon } from "@chakra-ui/core";

const Search = (props) => {
  const { setFilter, filter } = props;

  return (
    <InputGroup w="300px">
      <Input
        placeholder="Search by customer company ..."
        fontSize="12px"
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
        fontFamily="Avenir-Medium"
      />
      <InputRightElement children={<Icon name="search" color="#c4c4c4" />} />
    </InputGroup>
  );
};

export default Search;
