export default [
  {
    inputPlaceholder: "Customer Name",
    inputId: "customerCompanyName",
    type: "text",
    fieldName: "customerCompanyName",
    label: "Customer Company Name",
    isDisabled: true,
  },
  {
    inputPlaceholder: "Account Opened",
    inputId: "accountOpened",
    type: "date",
    fieldName: "accountOpened",
    label: "Account Opened",
  },
  {
    inputPlaceholder: "",
    inputId: "dateOfLastSale",
    type: "date",
    fieldName: "dateOfLastSale",
    label: "Date of Last Sale",
  },
  {
    inputPlaceholder: "",
    inputId: "paymentTerms",
    type: "select",
    fieldName: "paymentTerms",
    label: "Payment Terms",
  },
  {
    inputPlaceholder: "",
    inputId: "methodOfPayment",
    type: "text",
    fieldName: "methodOfPayment",
    label: "Method of Payment",
  },
  {
    inputPlaceholder: "",
    inputId: "highestCreditUsed",
    type: "text",
    fieldName: "highestCreditUsed",
    label: "Highest Credit Used",
  },
  {
    inputPlaceholder: "",
    inputId: "averageCreditUsed",
    type: "text",
    fieldName: "averageCreditUsed",
    label: "Average Payment Days",
  },
  {
    inputPlaceholder: "",
    inputId: "accountBalance",
    type: "text",
    fieldName: "accountBalance",
    label: "Account Balance",
  },
  {
    inputPlaceholder: "",
    inputId: "amountPastDue",
    type: "text",
    fieldName: "amountPastDue",
    label: "Amount Past Due",
  },
  {
    inputPlaceholder: "",
    inputId: "daysPastDue",
    type: "text",
    fieldName: "daysPastDue",
    label: "Days Past Due",
  },
  {
    inputPlaceholder: "",
    inputId: "additionalComments",
    type: "text",
    fieldName: "additionalComments",
    label: "Additional Comments",
    textArea: true,
  },

  {
    addDivider: true,
  },

  {
    addHeading: "Text here",
  },

  {
    inputPlaceholder: "",
    inputId: "referenceProviderName",
    type: "text",
    fieldName: "referenceProviderName",
    label: "Reference Provider's Name",
  },
  {
    inputPlaceholder: "",
    inputId: "referenceProviderCompany",
    type: "text",
    fieldName: "referenceProviderCompany",
    label: "Reference Provider's Company",
  },
];
