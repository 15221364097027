import validator from "validator";

export const validateEmail = (value) => {
  let error;
  if (!validator.isEmail(value) && !validator.isEmpty(value)) {
    error = "Email is not valid";
  } else if (validator.isEmpty(value)) {
    error = "Email is required";
  }
  return error;
};

export const validateName = (value) => {
  let error;
  const regex = /^[A-Za-z\s]{1,}[\\.]{0,1}[A-Za-z\s]{0,}$/g;
  const matchAlpha = regex.test(value);

  if (validator.isEmpty(value)) {
    error = "Name is required";
  } else if (!matchAlpha) {
    error = "Only A-Z letters are supported";
  }
  return error;
};

export const validateCompany = (value) => {
  let error;
  // const regex = /^[A-Za-z\s]{1,}[\\.]{0,1}[A-Za-z\s]{0,}$/g;
  // const matchAlpha = regex.test(value);

  if (validator.isEmpty(value)) {
    error = "Company Name is required";
  }
  return error;
};

export const validatePassword = (value) => {
  let error;

  if (validator.isEmpty(value)) {
    error = "Password is required";
  } else if (!validator.isAlphanumeric(value)) {
    error = "Password may contain only alpha-numeric characters";
  }
  return error;
};

export const validateString = (value) => {
  let error;

  if (!validator.isAlpha(value) && !validator.isEmpty(value)) {
    error = "Only A-Z letters are supported";
  } else if (validator.isEmpty(value)) {
    error = "This field is required";
  }
  return error;
};
