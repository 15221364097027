import React from "react";
import propTypes from "prop-types";

import {
  FormControl,
  Input,
  FormErrorMessage,
  FormLabel,
  Flex,
  Textarea,
  Divider,
  Heading
} from "@chakra-ui/core";

const CustomInput = props => {
  const {
    type,
    inputId,
    inputPlaceholder,
    isRequired,
    isInvalid,
    field,
    formErrorMessage,
    label,
    textArea,
    inputWidth,
    addDivider,
    addHeading,
    isDisabled
  } = props;

  const { name, onBlur, onChange, value } = field;

  const defaultProps = {
    bg: "gray.50",
    px: "14px",
    py: "10px",
    rounded: "5px",
    borderColor: "gray.200",
    color: "black",
    fontSize: "14px"
  };

  const changedInputField = inputWidth !== "100%";

  const renderContent = () => {
    if (addDivider) {
      return <Divider my="30px" />;
    }
    if (addHeading) {
      return (
        <Heading
          as="h4"
          size="18px"
          fontWeight="600"
          display="flex"
          textDecoration="underline"
          alignSelf="center"
          fontFamily="Avenir-Roman"
        >
          Submitter’s Information
        </Heading>
      );
    }
    return (
      <FormControl
        isInvalid={isInvalid}
        isRequired={isRequired}
        display={changedInputField ? "flex" : "block"}
        alignItems={changedInputField ? "flex-end" : "center"}
        flexDirection="column"
        isDisabled={isDisabled}
      >
        <Flex mt="25px">
          {label && (
            <FormLabel
              htmlFor="email"
              fontSize="15px"
              display="flex"
              alignItems="center"
              color="#1b1919"
              fontWeight="500"
            >
              {label}
            </FormLabel>
          )}

          {!textArea ? (
            <Input
              w={inputWidth}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              type={type}
              id={inputId}
              placeholder={inputPlaceholder}
              {...defaultProps}
              _placeholder={{ color: "#9a9ba2" }}
            />
          ) : (
            <Textarea
              w={inputWidth}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              type={type}
              id={inputId}
              {...defaultProps}
            />
          )}
        </Flex>
        <FormErrorMessage alignSelf="flex-end">
          {formErrorMessage}
        </FormErrorMessage>
      </FormControl>
    );
  };

  return renderContent();
};
CustomInput.propTypes = {
  inputPlaceholder: propTypes.string,
  type: propTypes.string,
  field: propTypes.objectOf(propTypes.any).isRequired,
  inputId: propTypes.string,
  formErrorMessage: propTypes.string,
  isRequired: propTypes.bool,
  isInvalid: propTypes.bool,
  inputWidth: propTypes.string
};
CustomInput.defaultProps = {
  inputPlaceholder: "placeholder",
  formErrorMessage: "Error",
  isRequired: false,
  isInvalid: false,
  inputWidth: "100%",
  type: "text"
};

export default CustomInput;
