import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import * as firebase from "firebase";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import ForgotPassword from "./routes/ForgotPassword";
import Dashboard from "./routes/Dashboard";
import CreditForm from "./routes/CreditForm";
import Protected from "./routes/Protected";
import { db } from "./firebase/firebase";

function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  // Merge user db data with firebase token
  useEffect(() => {
    const getData = async () => {
      if (user) {
        try {
          const userDbData = await db.db
            .collection("user")
            .doc(user.email)
            .get();
          const { company, fullName } = userDbData.data();
          user.company = company;
          user.fullName = fullName;
        } catch (err) {
          console.log(err);
        }
      }
    };

    getData();
  }, [user]);

  // Auth observer
  firebase.auth().onAuthStateChanged((e) => {
    if (e) {
      setUser(e);
    } else {
      setUser(null);
    }
  });

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const ProtectedRoute = (props) => {
    // eslint-disable-next-line
    const { children, path, exact } = props;
    return (
      <Protected exact={exact} path={path} user={user}>
        {children}
      </Protected>
    );
  };

  return (
    <div className="app">
      <Router>
        <Switch>
          <ProtectedRoute exact path="/">
            <Redirect to="/login" />
          </ProtectedRoute>
          <ProtectedRoute path="/login">
            <Login user={user} />
          </ProtectedRoute>
          <ProtectedRoute path="/signup">
            <SignUp user={user} />
          </ProtectedRoute>
          <ProtectedRoute path="/forgot-password">
            <ForgotPassword />
          </ProtectedRoute>
          <ProtectedRoute path="/dashboard">
            <Dashboard user={user} />
          </ProtectedRoute>
          <ProtectedRoute path="/form">
            <CreditForm user={user} />
          </ProtectedRoute>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
