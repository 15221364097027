import React from "react";
import { Route, Redirect } from "react-router-dom";
import propTypes from "prop-types";

const Protected = (props) => {
  const { exact, path, user, children } = props;

  const pathLogic = path !== "/dashboard";

  return user || (!user && pathLogic) ? (
    <Route path={path} user={user} exact={exact}>
      {children}
    </Route>
  ) : (
    <Redirect to="/login" />
  );
};

Protected.propTypes = {
  exact: propTypes.bool,
  path: propTypes.string.isRequired,
  user: propTypes.objectOf(propTypes.any),
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.any),
    propTypes.object,
  ]).isRequired,
};

Protected.defaultProps = {
  exact: false,
  user: null,
};

export default Protected;
