import React from "react";
import propTypes from "prop-types";
import { useTable, usePagination } from "react-table";
import styles from "./Table.module.css";
import Pagination from "./Pagination";

const Table = (props) => {
  const { data } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: "DATE",
        accessor: "date", // accessor is the "key" in the data
      },
      {
        Header: "CUSTOMER COMPANY",
        accessor: "customer_company",
      },
      {
        Header: "REFERENCE  COMPANY",
        accessor: "reference_company",
      },
      {
        Header: "REFERENCE  PERSON",
        accessor: "reference_person",
      },
      {
        Header: "CREDIT DETAILS",
        accessor: "credit_detail",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state: { pageIndex },
    prepareRow,
  } = useTable({ columns, data }, usePagination);

  return (
    <>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.rowHeader}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={styles.headerCell}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.rowBody}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={styles.bodyCell}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
    </>
  );
};

Table.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired,
};

export default Table;
