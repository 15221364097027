import React, { useEffect, useState } from "react";
import { Flex, Heading, Text, Spinner } from "@chakra-ui/core";
import { useLocation } from "react-router-dom";
import propTypes from "prop-types";
import Header from "../components/Header";
import BoxWhite from "../components/BoxWhite";
import FormCreditInquiry from "../components/FormCreditInquiry";
import { db } from "../firebase/firebase";

const ThankYou = (props) => {
  const [remainingTime, setRemainingTime] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(remainingTime - 1);
    }, 1000);
    if (remainingTime === 0) {
      clearInterval(interval);
      window.location.href = "https://appmozart.com/";
    }
  }, [remainingTime]);

  return (
    <BoxWhite
      w="600px"
      h="300px"
      p="58px"
      direction="column"
      align="center"
      justify="center"
    >
      <Heading as="h4" size="24px" display="flex">
        {`Thank you for submitting! You are going to be redirected to AppMozart
        official website in ${remainingTime} seconds.`}
      </Heading>

      <Spinner mt="10px" />
    </BoxWhite>
  );
};

const CreditForm = (props) => {
  const { user } = props;
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  // Params
  const uid = query.get("uid");
  const customerCompanyName = query.get("customerCompany");
  const id = query.get("id");
  const [isExternalLink, setIsExternalLink] = useState(null);
  const [dbValues, setDbValues] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (uid) {
      setIsExternalLink(true);
    } else {
      setIsExternalLink(false);
    }
  }, [uid]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await db.db
          .collection("user")
          .doc(user.email)
          .collection("reference")
          .doc(id)
          .get();

        const data = response.data();

        setDbValues(data);
      } catch (err) {
        console.error(err);
      }
    };

    if (!isExternalLink && user) {
      getData();
    }
  }, [isExternalLink, id, user]);

  return !submitted ? (
    <Flex w="100%" h="100%" direction="column" align="center">
      <Header user={user} />
      <BoxWhite w="900px" p="58px" pb="70px" direction="column" mb="100px">
        <Flex justify="center" alignItems="center" w="100%" direction="column">
          <Heading
            as="h4"
            size="18px"
            fontWeight="600"
            display="flex"
            textDecoration="underline"
          >
            Credit Inquiry
          </Heading>
          <Text mt="20px" fontSize="15px" color="#5e5e5e" textAlign="left">
            The following company has requested to setup a credit account with
            us and has listed your company as a trade reference. We would
            appreciate it if you would furnish us with the following
            information, which will be held in confidence.
          </Text>
        </Flex>

        <Flex w="90%" alignSelf="center" mt="30px">
          {(dbValues || isExternalLink) && (
            <FormCreditInquiry
              submitted={submitted}
              setSubmitted={setSubmitted}
              customerCompanyName={customerCompanyName}
              uid={uid}
              isExternalLink={isExternalLink}
              dbValues={dbValues}
            />
          )}
        </Flex>
      </BoxWhite>
    </Flex>
  ) : (
    <ThankYou />
  );
};

CreditForm.propTypes = {
  user: propTypes.objectOf(propTypes.any),
};

CreditForm.defaultProps = {
  user: null,
};

export default CreditForm;
