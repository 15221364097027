import { theme } from "@chakra-ui/core";

// Let's say you want to add custom colors
export default {
  ...theme,
  colors: {
    ...theme.colors,
    blue: {
      ...theme.colors.blue,
      700: "#3d5a80",
    },
    gray: {
      ...theme.colors.gray,
      50: "#f6f8f9",
    },
  },
};
