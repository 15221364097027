import React from "react";
import { useToast } from "@chakra-ui/core";
import BoxWhite from "../components/BoxWhite";
import Formik from "../components/Formik";
import { validateEmail } from "../utils/validators";
import { auth } from "../firebase/firebase";

function ForgotPassword() {
  const toast = useToast();
  const fields = [
    {
      inputPlaceholder: "Enter Email Address",
      inputId: "email",
      type: "email",
      fieldName: "email",
      validate: validateEmail,
    },
  ];

  const onSubmit = async (props) => {
    const { email } = props;
    try {
      await auth.sendResetPasswordEmail(email);
      toast({
        title: "Reset password link has been sent on your email.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: err,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <BoxWhite w={400} p="50px" direction="column" justify="center">
      <Formik
        initialValues={{ email: "" }}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonTitle="Send Recovery Link"
      />
    </BoxWhite>
  );
}

export default ForgotPassword;
