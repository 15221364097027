import React from "react";
import { Formik, Field } from "formik";
import { Flex } from "@chakra-ui/core";
import propTypes from "prop-types";
import FormControl from "./FormControl";
import Button from "./Button";

const CustomFormik = props => {
  const {
    onSubmit,
    initialValues,
    submitButtonTitle,
    fields,
    inputWidth,
    isDisabled,
    isLoading
  } = props;

  const renderFields = () => {
    return fields.length > 0 ? (
      fields.map((control, index) => {
        return (
          <Field
            name={control.fieldName}
            key={control.inputId ? control.inputId : index}
            validate={control.validate}
          >
            {({ field, form }) => (
              <FormControl
                isDisabled={isDisabled || control.isDisabled}
                inputId={control.inputId}
                type={control.type}
                inputPlaceholder={control.inputPlaceholder}
                isInvalid={
                  form.errors[control.fieldName] &&
                  form.touched[control.fieldName]
                }
                field={field}
                formErrorMessage={form.errors[control.fieldName]}
                withLabel={control.withLabel}
                label={control.label}
                textArea={control.textArea}
                noInput={control.noInput}
                inputWidth={inputWidth}
                addDivider={control.addDivider}
                addHeading={control.addHeading}
              />
            )}
          </Field>
        );
      })
    ) : (
      <div>Add form control</div>
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {localProps => (
        <form
          onSubmit={localProps.handleSubmit}
          noValidate
          style={{
            width: inputWidth !== "100%" ? "auto" : "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {renderFields()}

          {submitButtonTitle && !isDisabled && (
            <Flex justify="center">
              <Button
                type="submit"
                isLoading={isLoading}
                submitButtonTitle={submitButtonTitle}
              />
            </Flex>
          )}
        </form>
      )}
    </Formik>
  );
};

CustomFormik.propTypes = {
  onSubmit: propTypes.func.isRequired,
  fields: propTypes.arrayOf(propTypes.object),
  submitButtonTitle: propTypes.string,
  initialValues: propTypes.objectOf(propTypes.any).isRequired,
  inputWidth: propTypes.string
};

CustomFormik.defaultProps = {
  fields: [],
  submitButtonTitle: null,
  inputWidth: "100%"
};

export default CustomFormik;
