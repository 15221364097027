import React, { useEffect, useState } from "react";
import { Box, useToast } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import BoxWhite from "../components/BoxWhite";
import LinkBlue from "../components/Link/LinkBlue";
import Formik from "../components/Formik";
import { validateEmail, validatePassword } from "../utils/validators";
import { auth } from "../firebase/firebase";

const Login = () => {
  const toast = useToast();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setCurrentUser(auth.getCurrentUser());
  }, []);

  useEffect(() => {
    if (currentUser) {
      toast({
        title: "Welcome",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Route to /dashboard
      history.replace("/dashboard");
    }
  }, [currentUser, history, toast]);

  const fields = [
    {
      inputPlaceholder: "Enter Email Address",
      inputId: "email",
      type: "email",
      fieldName: "email",
      validate: validateEmail,
    },
    {
      inputPlaceholder: "Enter Password",
      inputId: "password",
      type: "password",
      fieldName: "password",
      validate: validatePassword,
    },
  ];

  const onSubmit = async (prop) => {
    const { email, password } = prop;

    try {
      // Sign in
      await auth.signIn(email, password);
    } catch (err) {
      toast({
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <BoxWhite w={400} p="50px" direction="column" justify="center">
      <Formik
        initialValues={{ email: "", password: "" }}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonTitle="Login"
        routeTo="/dashboard"
      />

      <Box mt="10px">
        <LinkBlue mr="6px" to="/signup">
          Sign Up
        </LinkBlue>
        <LinkBlue ml="6px" to="/forgot-password">
          Forgot Password?
        </LinkBlue>
      </Box>
    </BoxWhite>
  );
};

export default Login;
