import React, { useState, useEffect } from "react";
import { Heading, useToast } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import Modal from "./Modal";
import BoxWhite from "../BoxWhite";
import Formik from "../Formik";
import { requestNewReference, db } from "../../firebase/firebase";

import {
  validateCompany,
  validateName,
  validateEmail,
} from "../../utils/validators";

const RequestNewReference = (props) => {
  const { user, isOpen, onOpen, onClose } = props;
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSubmitted(false);
    }
  }, [isOpen]);

  const toast = useToast();
  const fields = [
    {
      inputPlaceholder: "Customer Company Name",
      inputId: "customerCompanyName",
      type: "text",
      fieldName: "customerCompanyName",
      validate: validateCompany,
    },
    {
      inputPlaceholder: "Reference Provider Company",
      inputId: "referenceProviderCompany",
      type: "text",
      fieldName: "referenceProviderCompany",
      validate: validateCompany,
    },
    {
      inputPlaceholder: "Reference Provider Name",
      inputId: "referenceProviderName",
      type: "text",
      fieldName: "referenceProviderName",
      validate: validateName,
    },
    {
      inputPlaceholder: "Reference Provider Email",
      inputId: "referenceProviderEmail",
      type: "email",
      fieldName: "referenceProviderEmail",
      validate: validateEmail,
    },
  ];

  const onSubmit = async ({
    customerCompanyName,
    referenceProviderCompany,
    referenceProviderName,
    referenceProviderEmail,
  }) => {
    setSubmitted(true);

    setTimeout(() => {
      onClose();
    }, 2000);

    // Check if ref already exist
    const referenceQuery = await db.checkIfReferenceExist({
      user,
      customerCompanyName,
      referenceProviderName,
      referenceProviderCompany,
    });

    if (!referenceQuery.empty) {
      toast({
        title: "Database already contain requested reference",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const { fullName, company } = user;

      const response = await requestNewReference({
        customerCompanyName,
        referenceProviderEmail,
        fullName,
        company,
      });
      if (response.data.ok) {
        toast({
          title: "Your request has been sent!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.replace("/dashboard");
      } else {
        const errMsg = response.data.error;

        toast({
          title: errMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal user={user} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <BoxWhite
        rounded="md"
        p="30px 50px"
        w="100%"
        justify="center"
        align="center"
        direction="column"
      >
        <Heading
          as="h5"
          color="#5e5e5e"
          fontSize="22px"
          fontWeight="500"
          alignSelf="flex-start"
        >
          Request New Trade Reference
        </Heading>

        <Formik
          initialValues={{
            customerCompanyName: "",
            referenceProviderCompany: "",
            referenceProviderName: "",
            referenceProviderEmail: "",
          }}
          fields={fields}
          onSubmit={onSubmit}
          submitButtonTitle="Send"
          isLoading={submitted}
        />
      </BoxWhite>
    </Modal>
  );
};

export default RequestNewReference;
