import React from "react";
import { Flex, Heading, Avatar, useDisclosure } from "@chakra-ui/core";
import { Link as RouteLink } from "react-router-dom";
import propTypes from "prop-types";
import ChangePersonalDetailsModal from "./Modal/ChangePersonalDetails";

const Header = (props) => {
  const { user } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log(user);
  return (
    <>
      <Flex
        mb="60px"
        boxShadow="md"
        bg="#FBFCFC"
        px="16"
        height="80px"
        width="100%"
        position="relative"
        justify="space-between"
        top="0"
        py="10"
        align="center"
      >
        <Heading
          as={RouteLink}
          to="/dashboard"
          fontSize="30px"
          lineHeight="0"
          color="blue.700"
          fontFamily="Avenir-Medium"
        >
          {user && user.company}
        </Heading>

        <Flex>
          <Heading fontSize="14px" mr="10px" display="flex" alignItems="center">
            {user && user.displayName}
          </Heading>

          <Avatar
            as="button"
            borderColor="#E5E5E5"
            showBorder
            src="https://bit.ly/broken-link"
            size="sm"
            onClick={onOpen}
          />
        </Flex>
      </Flex>

      <ChangePersonalDetailsModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};

Header.propTypes = {
  user: propTypes.objectOf(propTypes.any),
};

Header.defaultProps = {
  user: null,
};

export default Header;
