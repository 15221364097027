import React from "react";
import { IconButton, Flex, Box, Heading } from "@chakra-ui/core";
import propTypes from "prop-types";

const Pagination = (props) => {
  const {
    nextPage,
    previousPage,
    pageIndex,
    canPreviousPage,
    canNextPage,
  } = props;

  return (
    <Flex mt="13px" width="auto" justify="space-between" mb="50px">
      <Heading as="h5" size="xs" color="gray.400">
        {`PAGE: ${pageIndex}`}
      </Heading>

      <Box>
        <IconButton
          bg="#004d7f"
          icon="chevron-left"
          color="white"
          size="md"
          _hover={{ bg: "blue.800" }}
          onClick={previousPage}
          disabled={!canPreviousPage}
        />
        <IconButton
          ml="15px"
          bg="#004d7f"
          icon="chevron-right"
          color="white"
          size="md"
          disabled={!canNextPage}
          _hover={{ bg: "blue.800" }}
          onClick={nextPage}
        />
      </Box>
    </Flex>
  );
};

Pagination.propTypes = {
  nextPage: propTypes.func.isRequired,
  previousPage: propTypes.func.isRequired,
  pageIndex: propTypes.number.isRequired,
  canPreviousPage: propTypes.bool.isRequired,
  canNextPage: propTypes.bool.isRequired,
};

export default Pagination;
