import React from "react";
import { Box, useToast } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import BoxWhite from "../components/BoxWhite";
import LinkBlue from "../components/Link/LinkBlue";
import Formik from "../components/Formik";

import {
  validateName,
  validatePassword,
  validateEmail,
} from "../utils/validators";
import { auth, db } from "../firebase/firebase";

const SignUp = () => {
  const toast = useToast();
  const history = useHistory();
  const fields = [
    {
      inputPlaceholder: "Enter Full Name",
      inputId: "name",
      type: "name",
      fieldName: "name",
      validate: validateName,
    },
    {
      inputPlaceholder: "Enter Company Name",
      inputId: "company",
      type: "company",
      fieldName: "company",
      validate: validateName,
    },
    {
      inputPlaceholder: "Enter Email Address",
      inputId: "email",
      type: "email",
      fieldName: "email",
      validate: validateEmail,
    },
    {
      inputPlaceholder: "Enter Password",
      inputId: "password",
      type: "password",
      fieldName: "password",
      validate: validatePassword,
    },
  ];

  const onSubmit = async ({ name, email, company, password }) => {
    try {
      // Sign up
      await auth.signUp(email, password);

      // Add user info
      await db.addDoc("user", {
        fullName: name,
        email,
        company,
        id: email,
      });

      await auth.updateUsersDisplayName({ fullName: name });

      toast({
        title: "Welcome",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Route to /dashboard
      history.replace("/dashboard");
    } catch (err) {
      toast({
        description: err.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <BoxWhite w={400} p="50px" direction="column" justify="center">
      <Formik
        initialValues={{ email: "", password: "", name: "", company: "" }}
        fields={fields}
        onSubmit={onSubmit}
        submitButtonTitle="Sign Up"
      />

      <Box mt="10px">
        <LinkBlue mr="6px" to="login">
          Login
        </LinkBlue>
        <LinkBlue ml="6px" to="forgot-password">
          Forgot Password?
        </LinkBlue>
      </Box>
    </BoxWhite>
  );
};

export default SignUp;
