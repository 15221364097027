import React from "react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/core";
import propTypes from "prop-types";

const CustomModal = (props) => {
  const { onClose, isOpen, children } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="680px"
      preserveScrollBarGap
      returnFocusOnClose
    >
      <ModalOverlay />
      <ModalContent rounded="md">{children}</ModalContent>
    </Modal>
  );
};

CustomModal.propTypes = {
  children: propTypes.element.isRequired,
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
};

export default CustomModal;
