import React from "react";
import { Flex } from "@chakra-ui/core";
import propTypes from "prop-types";

const BoxWhite = (props) => {
  const { children } = props;

  return (
    <Flex bg="white" boxShadow="md" {...props} rounded="md">
      {children}
    </Flex>
  );
};

BoxWhite.propTypes = {
  children: propTypes.oneOfType([
    propTypes.object,
    propTypes.arrayOf(propTypes.element),
  ]).isRequired,
};

export default BoxWhite;
