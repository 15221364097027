import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAtSs_dpn7Ow0Rx2gEgYqZMrIHEH-Wu6Q4",
  authDomain: "credit-inquiry-prodution.firebaseapp.com",
  databaseURL: "https://credit-inquiry-prodution.firebaseio.com",
  projectId: "credit-inquiry-prodution",
  storageBucket: "credit-inquiry-prodution.appspot.com",
  messagingSenderId: "488848596256",
  appId: "1:488848596256:web:30ca6c0637ee32509acb45",
  measurementId: "G-7MZHGD2LNQ"
};

firebase.initializeApp(firebaseConfig);
// app.functions().useFunctionsEmulator("http://localhost:5001");

export const auth = {
  auth: firebase.auth(),

  signUp(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  },

  signIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  },

  signOut() {
    return this.auth.signOut();
  },

  updateProfile(data) {
    return this.auth.currentUser.updateProfile(data);
  },

  getCurrentUser() {
    return this.auth.currentUser;
  },

  updateUsersDisplayName({ fullName }) {
    return this.auth.currentUser.updateProfile({
      displayName: fullName
    });
  },

  updateUsersEmail(email) {
    return this.auth.currentUser.updateEmail(email);
  },

  updateUsersPassword(password) {
    return this.auth.currentUser.updatePassword(password);
  },

  sendResetPasswordEmail(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
};

export const db = {
  db: firebase.firestore(),

  addDoc(collectionId, { id, ...rest }) {
    return this.db
      .collection(collectionId)
      .doc(id)
      .set(rest);
  },

  updateDoc(collectionId, { id, ...rest }) {
    return this.db
      .collection(collectionId)
      .doc(id)
      .update(rest);
  },

  getAllReferences(user) {
    return this.db
      .collection("user")
      .doc(user.email)
      .collection("reference")
      .get();
  },

  checkIfReferenceExist({
    user,
    referenceProviderName,
    referenceProviderCompany,
    customerCompanyName
  }) {
    return this.db
      .collection("user")
      .doc(user.email)
      .collection("reference")
      .where("referenceProviderName", "==", referenceProviderName)
      .where("referenceProviderCompany", "==", referenceProviderCompany)
      .where("customerCompanyName", "==", customerCompanyName)
      .get();
  }
};

export const requestNewReference = firebase
  .functions()
  .httpsCallable("sendReferenceRequest");

export const sendFilledForm = firebase
  .functions()
  .httpsCallable("sendFilledForm");
