import React from "react";
import { PseudoBox } from "@chakra-ui/core";
import propTypes from "prop-types";

const CustomButton = (props) => {
  const { type, submitButtonTitle, isLoading } = props;

  return (
    <PseudoBox
      as="button"
      backgroundColor="blue.700"
      size="lg"
      mt="8"
      disabled={isLoading}
      color="white"
      boxShadow="md"
      width="167px"
      height="42px"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      rounded="5px"
      fontFamily="Avenir-Book"
      type={type}
      _hover={{ bg: "blue.900", color: "white" }}
      _disabled={{ bg: "silver" }}
    >
      {isLoading ? "Sending..." : submitButtonTitle}
    </PseudoBox>
  );
};

CustomButton.propTypes = {
  type: propTypes.string,
  submitButtonTitle: propTypes.string,
};

CustomButton.defaultProps = {
  type: "button",
  submitButtonTitle: "Button",
};

export default CustomButton;
